#resetPasswordForm {
  text-align: left;
}

.label-lc {
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 25px;
  display: block;
}

.swal2-input {
  width: 400px;
  margin-bottom: 25px;
  margin-left: 25px;
  padding: 10px;
}
.custom-input-class {
  width: 90%;
}
.table-lc {
  width: 100%;
  border-collapse: collapse;
}

.table-lc th,
.table-lc td {
  border: 1px solid #ddd;
  padding: 10px 3px 10px 3px;
  text-align: left;
}
@media (max-width: 600px) {
  /* Adjust styles for smaller screens */
  .table-lc th,
  .table-lc td {
    padding: 8px 2px;
    font-size: 14px;
  }
}

/* Form Error Text */

.lc_form_error_text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--vz-form-invalid-color);
}
.custom-select-medium {
  /* padding: 0.375rem 0.75rem;   */
  height: 2rem;
}
.custom-modal .modal-content {
  max-width: 450px;
  max-height: 600px;
  padding: 0px;
}
.my-select {
  font-size: 13px;
  color: #879cca;
  padding: 6px 30px 6px 8px; /* Adjusting the left side padding */
  background-color: #f3f6f9;
  color: #f3f6f9;
  border: var(--vz-border-width) solid #e9ebec;
}
.custom-modal-1 .modal-content {
  max-width: 600px;
  max-height: 600px;
  padding: 0px;
}
.eye-icon {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
}

.lc_multi_select .css-wsp0cs-MultiValueGeneric {
  color: white !important;
}
